/*
====================
For Mobile
====================
*/
@media screen and (max-width: 629px) {
	.sticky-top {
		display: none;
	}
	section {
		margin: 0 -75% 0 -15%;
	}
	.bodycol {
		margin-left: none !important;
	}
	.homeHero {
		padding-left: 25%;
		padding-bottom: 42vh;
	}
	.homeTitle {
		font-size: 3rem;
	}
	.aboutButton {
		margin-left: 0.5rem;
	}
	.aboutPage {
		.aboutText {
			font-size: 1rem;
			margin-left: 2%;
		}
	}
	.accordion__title {
		font-size: 0.7rem;
	}
	.accordion__text {
		font-size: 0.7rem;
	}
	.foodText {
		padding-top: 0;
		font-size: 0.8rem;
	}
	.travelText {
		font-size: 0.8rem;
	}
}
/*
====================
For Tablet
====================
*/

@media screen and (min-width: 630px) and (max-width: 767px) {
	section {
		padding-bottom: 12vh;
	}
	.section-nav {
		height: 105%;
	}
	.homeTitle {
		font-size: 3rem;
	}
	.aboutButton {
		margin-left: .5rem;
	}
	.accordion__title {
		font-size: 0.7rem;
	}
	.accordion__text {
		font-size: 0.7rem;
	}
	.aboutText {
		font-size: 0.9rem;
	}
	.aboutPage {
		padding-bottom: 11rem;
	}
	.foodText {
		padding-top: 0;
		font-size: 0.9rem;
	}
	.travelText {
		font-size: 0.9rem;
	}
}

/*
====================
For Small Laptops
====================
*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
	section {
		padding-bottom: 12vh;
	}
	.section-nav {
		height: 105%;
	}
	.homeTitle {
		font-size: 3rem;
	}
	.aboutButton {
		margin-left: .5rem;
	}
	.accordion__title {
		font-size: 0.8rem;
	}
	.accordion__text {
		font-size: 0.8rem;
	}
	.aboutText {
		font-size: 0.9rem;
	}
	.aboutPage {
		padding-bottom: 11rem;
	}
	.foodText {
		padding-top: 0;
		font-size: 0.7rem;
	}
	.travelText {
		font-size: 0.7rem;
	}
}

/*
====================
For Large Laptops
====================
*/
@media screen and (min-width: 1025px) and (max-width: 1440px) {

}

/*
====================
For 4k and above
====================
*/
@media screen and (min-width: 1441px) {

}