$grey: #E1DAD2;
$pink: #D8A28C;
$orange: #D37556;
$green: #18d567;
$lightgreen: #0dc49c;
$darkblue: #0C2431;
$red: #F76C6C;
$yellow: #F8E9A1;
$lightblue: #A8D0E6;
$blue: #374785;
$royalblue: #24305E;
$wordred: #F4A8A8;
$wordyellow: #F5EECA;
$wordblue: #C1D9E7;
$wordblue2: #9AC9E5;
$lightrose: #e48186;
$rose: #c8777b;
$darkrose: #a05557;
$forest: #0f4b39;