@import '../../styles/variables.scss';
@import '../../styles/mediaqueries.scss';

/* 1. Enable smooth scrolling */
html {
    scroll-behavior: smooth;
    padding: 0 !important;
}

/* 2. Make nav sticky */
.sticky-top {
    position: fixed;
    align-self: start;
    height: 100vh;
    padding: 0 !important;
}

/* 3. ScrollSpy active styles (see JS tab for activation) */
.section-nav li.active > a {
    font-weight: 600rem;
}

/* Sidebar Navigation */
.section-nav {
    padding: 10% 5% 5% 10%;
    background-color: $rose;
    text-align: center;
    height: 100%;
}

.section-nav a {
    text-decoration: none;
    font-family: Avenir;
    display: block;
    height: 12vh;
    color: white;
    font-weight: 450;
    transition: all 50ms ease-in-out; /* 💡 This small transition makes setting of the active state smooth */
}

.section-nav a:hover,
.section-nav a:focus {
    text-decoration: none;
    color: white;
    font-weight: 700;
}

.homelogo {
    width: 60%;
}

#home, #profile, #work, #leadership, #skills, #contact {
    background: white;
    color: $rose;
}

#edu, #projects, #interests {
    background: $forest;
    color: white;
}

.bodycol {
    padding: 0 !important;
    text-align: left;
    margin-left: 16%;
}
/** Poor man's reset **/



ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

/** page layout **/

/** enlarge the sections for this demo, so that we have a long scrollable page **/
section {
    font-weight: 400;
    font-family: Avenir;
    padding: 2vh 3vh 10vh;
}